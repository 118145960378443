import { useEffect, type PropsWithChildren } from 'react';
import { useFormState, useFormStatus } from 'react-dom';
import { Root, Submit } from '@radix-ui/react-form';
import { radixColorScales } from '@radix-ui/themes';

import { Button, Text, type ButtonProps } from '@/components/radix';
import type {
  FormProps as BaseFormProps,
  ServerAction,
} from '@/components/forms/types';

const i18n = {
  en: {
    actions: {
      submit: 'Save',
    },
  },
};

export interface ActionSubmitButtonProps
  extends PropsWithChildren<Omit<ButtonProps, 'color'>> {
  disabled?: boolean;
  className?: string;
  color?: (typeof radixColorScales)[number];
}

export function ActionSubmitButton({
  variant = 'solid',
  size = '4',
  children = i18n.en.actions.submit,
  className,
  ...props
}: ActionSubmitButtonProps) {
  return (
    <div className="flex w-full justify-end">
      <Button
        {...props}
        type="submit"
        variant={variant}
        size={size}
        className={className || 'w-[160px] max-w-sm'}
        asChild
      >
        <Submit>
          <Text as="span" size="3" weight="bold">
            {children}
          </Text>
        </Submit>
      </Button>
    </div>
  );
}

export function ActionFormSubmitButton({
  disabled = false,
  ...props
}: ActionSubmitButtonProps) {
  const { pending } = useFormStatus();

  return <ActionSubmitButton disabled={disabled || pending} {...props} />;
}

export interface ActionFormRelayProps {
  state: any;
  onLoading?: (payload: any) => void | Promise<void>;
  onSuccess?: (payload: any) => void | Promise<void>;
  onError?: (payload: any) => void | Promise<void>;
}

export function ActionFormRelay({
  state,
  onSuccess,
  onError,
  onLoading,
}: ActionFormRelayProps) {
  const { pending, data } = useFormStatus();

  useEffect(() => {
    const payload = { data, state, pending };
    if (pending === false) {
      if (state.success) {
        onSuccess && onSuccess(payload);
      } else if (state.error) {
        onError && onError(payload);
      }
    } else {
      onLoading && onLoading(payload);
    }
  }, [pending, state, data]);

  return null;
}

export interface ActionFormProps
  extends PropsWithChildren<BaseFormProps>,
    Omit<ActionFormRelayProps, 'state'> {
  className?: string;
  initialState?: any;
}

export function ActionForm({
  onSubmit,
  action,
  children,
  onSuccess,
  onError,
  onLoading,
  initialState = {},
  ...props
}: ActionFormProps) {
  const [state, dispatch] = useFormState(action as ServerAction, initialState);

  return (
    <Root onSubmit={onSubmit} action={dispatch} {...props}>
      <ActionFormRelay
        state={state}
        onSuccess={onSuccess}
        onError={onError}
        onLoading={onLoading}
      />
      {children}
    </Root>
  );
}
