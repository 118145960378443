import type { AnchorHTMLAttributes, PropsWithChildren } from 'react';
import Link, { LinkProps } from 'next/link';
import { Link as AnchorLink } from '@radix-ui/themes';

export interface AnchorProps
  extends PropsWithChildren<LinkProps<AnchorHTMLAttributes<HTMLAnchorElement>>> {}

export function Anchor({ children, ...props }: AnchorProps) {
  return (
    <AnchorLink asChild color="grass">
      <Link
      {...props}
    >
      {children}
    </Link>
    </AnchorLink>
  );
}
