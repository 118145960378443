'use client';

import type { FieldProps } from '@/components/forms/types';
import type { FormAction, ServerAction } from '@/types/api';
import { useState, Children, type PropsWithChildren } from 'react';
import { useFormState, useFormStatus } from 'react-dom';
import * as Form from '@radix-ui/react-form';
import {
  EyeIcon,
  EyeOffIcon,
  KeyIcon,
  KeyRoundIcon,
  LockIcon,
  MailIcon,
  PhoneIcon,
} from 'lucide-react';
import {
  Text,
  TextField,
  Checkbox,
  IconButton,
  Button,
} from '@/components/radix';
import { Anchor } from '@/components/primitives';
import { publicLinks } from '@/constants/links';
import {
  CSRF_FIELD_NAME,
  PHONE_FIELD_NAME,
  EMAIL_FIELD_NAME,
  PASSWORD_FIELD_NAME,
  NEW_PASSWORD_FIELD_NAME,
  CONFIRM_PASSWORD_FIELD_NAME,
  REMEMBER_ME_FIELD_NAME,
} from '@/constants/client/field-names';
import { ActionForm, ActionFormProps } from './ActionForm';

const i18n = {
  en: {
    fields: {
      phone: {
        label: 'Phone Number',
        placeholder: '123 456 7890',
      },
      email: {
        label: 'Email address',
        placeholder: '',
      },
      password: {
        label: 'Password',
        placeholder: '',
      },
      passwordNew: {
        label: 'New password',
        placeholder: '',
      },
      passwordConfirm: {
        label: 'Confirm password',
        placeholder: '',
      },
      rememberMe: {
        label: 'Remember me',
      },
    },
    actions: {
      showPassword: 'Show password',
      hidePassword: 'Hide password',
      forgotPassword: 'Forgot password?',
      submit: 'Submit',
      forgotPasswordSubmit: 'Send',
      resetPasswordSubmit: 'Reset Password',
      continueSubmit: 'Continue',
    },
    errors: {
      // TODO: make error messages more human-readable
      missingEmail: 'Please enter an email.',
      missingPassword: 'Please enter a password.',
      invalidEmail: 'Please include a valid email address.',
      invalidPassword: 'Please include a valid password.',
      invalidPasswordLength: '8+ characters required.',
      invalidCredentials: 'The email or password provided is not correct.',
      passwordMismatch: 'The passwords provided do not match.',
    },
  },
  links: {
    forgotPassword: publicLinks.forgotPassword,
  },
};

export interface CSRFInputProps {
  name?: string;
  token?: string;
}

export function CSRFInput({
  name = CSRF_FIELD_NAME,
  token: csrfToken,
}: CSRFInputProps) {
  return <input name={name} type="hidden" defaultValue={csrfToken} />;
}

export interface EmailFieldProps extends FieldProps<string> {
  label?: string;
  placeholder?: string;
  className?: string;
}

export function EmailField({
  name = EMAIL_FIELD_NAME,
  label,
  placeholder,
  className,
  children,
  defaultValue,
  value,
  onChange,
}: EmailFieldProps) {
  const [badge, ...messages] = Children.toArray(children);

  return (
    <Form.Field name={name} className="group flex flex-col gap-y-2">
      <div className="flex items-end justify-between">
        <Form.Label asChild>
          <Text as="label" weight="bold" size="2">
            {label || i18n.en.fields.email.label}
          </Text>
        </Form.Label>

        {badge}
      </div>

      <Form.Control asChild>
        <TextField.Root
          className="rounded-md group-data-[invalid=true]:bg-warning group-data-[invalid=true]:outline-warning"
          // className={className}
          defaultValue={defaultValue}
          value={value}
          variant="surface"
          size="3"
          required
          type="email"
          autoComplete="email"
          placeholder={placeholder || i18n.en.fields.email.placeholder}
          // @ts-ignore
          onChange={onChange}
        >
          <TextField.Slot>
            <MailIcon height="16" width="16" />
          </TextField.Slot>
        </TextField.Root>
      </Form.Control>

      <Form.Message match="typeMismatch">
        <Text color="amber" size="2">
          {i18n.en.errors.invalidEmail}
        </Text>
      </Form.Message>
      <Form.Message match="valueMissing">
        <Text color="amber" size="2">
          {i18n.en.errors.missingEmail}
        </Text>
      </Form.Message>
      {messages}
    </Form.Field>
  );
}

export interface PhoneNumberFieldProps extends FieldProps<string> {
  label?: string;
  placeholder?: string;
  className?: string;
}

export function PhoneNumberField({
  name = PHONE_FIELD_NAME,
  label,
  placeholder,
  className,
  children,
  value,
  defaultValue,
  onChange,
}: PhoneNumberFieldProps) {
  const [badge, ...messages] = Children.toArray(children);

  return (
    <Form.Field name={name} className="group flex flex-col gap-y-2">
      <div className="flex items-end justify-between">
        <Form.Label asChild>
          <Text as="label" weight="bold" size="2">
            {label || i18n.en.fields.phone.label}
          </Text>
        </Form.Label>

        {badge}
      </div>

      <Form.Control asChild>
        <TextField.Root
          className={className}
          defaultValue={defaultValue}
          value={value}
          variant="surface"
          size="3"
          autoComplete="tel"
          type="tel"
          placeholder={placeholder || i18n.en.fields.phone.placeholder}
          onChange={onChange as any}
        >
          <TextField.Slot>
            <PhoneIcon height="16" width="16" />
          </TextField.Slot>

          {/* TODO: follow best practices, add phone number validation
           * https://uxplanet.org/phone-number-field-design-best-practices-23957cbd86d5
           */}
        </TextField.Root>
      </Form.Control>

      {/* TODO: add messaging for phone field */}

      {messages}
    </Form.Field>
  );
}

export interface PasswordFieldProps extends FieldProps<string> {
  className?: string;
  label?: string;
  autoComplete?: string;
  showForgotPassword?: boolean;
  icon?: React.ReactNode | null;
  disabled?: boolean;
}

export function PasswordField({
  name = PASSWORD_FIELD_NAME,
  className,
  label = i18n.en.fields.password.label,
  autoComplete = 'current-password',
  showForgotPassword = false,
  icon = null,
  disabled,
  children,
}: PasswordFieldProps) {
  const [badge, ...messages] = Children.toArray(children);

  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  return (
    <Form.Field name={name} className="border-1 group flex flex-col gap-y-2">
      <div className="flex items-end justify-between">
        <Form.Label asChild>
          <Text as="label" weight="bold" size="2" className="flex-1">
            {label}
          </Text>
        </Form.Label>

        {showForgotPassword && (
          <Anchor href={i18n.links.forgotPassword}>
            <Text as="span" weight="bold" size="2">
              {i18n.en.actions.forgotPassword}
            </Text>
          </Anchor>
        )}

        {badge}
      </div>

      <Form.Control asChild>
        <TextField.Root
          className="rounded-md group-data-[invalid=true]:bg-warning group-data-[invalid=true]:outline-warning"
          // className={className}
          disabled={disabled}
          variant="surface"
          size="3"
          required
          minLength={8}
          type={showPassword ? 'text' : 'password'}
          autoComplete={autoComplete}
          placeholder={i18n.en.fields.password.placeholder}
        >
          <TextField.Slot>{icon || <KeyRoundIcon size={16} />}</TextField.Slot>
          <TextField.Slot className="me-1">
            {/* TODO: update colors on invalid state */}
            {!showPassword ? (
              <IconButton
                type="button"
                variant="ghost"
                onClick={togglePasswordVisibility}
                aria-label={i18n.en.actions.hidePassword}
              >
                <EyeIcon height="16" width="16" />
              </IconButton>
            ) : (
              <IconButton
                type="button"
                variant="ghost"
                onClick={togglePasswordVisibility}
                aria-label={i18n.en.actions.showPassword}
              >
                <EyeOffIcon height="16" width="16" />
              </IconButton>
            )}
          </TextField.Slot>
        </TextField.Root>
      </Form.Control>

      <Form.Message match="typeMismatch">
        <Text color="amber" size="2">
          {i18n.en.errors.invalidPassword}
        </Text>
      </Form.Message>
      <Form.Message match="tooShort">
        <Text color="amber" size="2">
          {i18n.en.errors.invalidPasswordLength}
        </Text>
      </Form.Message>
      <Form.Message match="valueMissing">
        <Text color="amber" size="2">
          {i18n.en.errors.missingPassword}
        </Text>
      </Form.Message>

      {messages}
    </Form.Field>
  );
}

export function NewPasswordField() {
  return (
    <PasswordField
      name={NEW_PASSWORD_FIELD_NAME}
      autoComplete="new-password"
      label={i18n.en.fields.passwordNew.label}
      icon={<KeyIcon size={16} />}
    />
  );
}

export function ConfirmPasswordField() {
  return (
    <PasswordField
      name={CONFIRM_PASSWORD_FIELD_NAME}
      autoComplete="new-password"
      label={i18n.en.fields.passwordConfirm.label}
      icon={<LockIcon size={16} />}
    />
  );
}

export interface RememberMeFieldProps {
  name?: string;
}

export function RememberMeField({
  name = REMEMBER_ME_FIELD_NAME,
}: RememberMeFieldProps) {
  return (
    <Form.Field name={name} className="flex items-center gap-2 ps-1">
      <Form.Control asChild>
        {/* TODO: clear button for accessibility */}
        <Checkbox />
      </Form.Control>

      <Form.Label asChild>
        <Text as="label" weight="medium" size="2">
          {i18n.en.fields.rememberMe.label}
        </Text>
      </Form.Label>
    </Form.Field>
  );
}

export interface SubmitButtonProps extends PropsWithChildren {
  disabled?: boolean;
  className?: string;
}

export function SubmitButton({
  children = i18n.en.actions.submit,
  disabled,
  className,
}: SubmitButtonProps) {
  const { pending } = useFormStatus();

  return (
    <Form.Submit asChild>
      <Button className={className} disabled={disabled || pending} size="4">
        <Text as="span" size="3" weight="bold">
          {children}
        </Text>
      </Button>
    </Form.Submit>
  );
}

export function ForgotPasswordSubmitButton({
  children,
  ...props
}: SubmitButtonProps) {
  return (
    <SubmitButton {...props}>
      {children || i18n.en.actions.forgotPasswordSubmit}
    </SubmitButton>
  );
}

export function ResetPasswordSubmitButton({
  children,
  ...props
}: SubmitButtonProps) {
  return (
    <SubmitButton {...props}>
      {children || i18n.en.actions.resetPasswordSubmit}
    </SubmitButton>
  );
}

export function ContinueSubmitButton({
  children,
  ...props
}: SubmitButtonProps) {
  return (
    <SubmitButton {...props}>
      {children || i18n.en.actions.continueSubmit}
    </SubmitButton>
  );
}

export interface StateErrorsProps {
  state?: {
    errors?: {
      message?: string;
      credentials?: boolean;
      passwordMismatch?: boolean;
    };
  };
}

export function StateErrors({
  state = { errors: { credentials: false } },
}: StateErrorsProps) {
  return (
    <>
      {state?.errors?.message ? (
        <Text as="p" size="2" color="red">
          {state.errors.message}
        </Text>
      ) : null}
      {state?.errors?.credentials ? (
        <Text as="p" size="2" color="red">
          {i18n.en.errors.invalidCredentials}
        </Text>
      ) : null}
      {state?.errors?.passwordMismatch ? (
        <Text as="p" size="2" color="red">
          {i18n.en.errors.passwordMismatch}
        </Text>
      ) : null}
    </>
  );
}

export interface FormLayoutProps extends ActionFormProps {
  action?: FormAction;
  csrfToken?: string;
  initialState?: Record<string, any>;
}

export function FormLayout({
  action,
  csrfToken,
  initialState,
  children,
  ...props
}: PropsWithChildren<FormLayoutProps>) {
  const [state, setState] = useState(() => ({
    ...initialState,
    errors: {},
  }));
  const [fields, submitButton] = Children.toArray(children);

  return (
    <ActionForm
      className="grid gap-y-6"
      action={action}
      initialState={state}
      onError={(payload) => {
        setState(payload.state);
      }}
      {...props}
    >
      <CSRFInput token={csrfToken} />

      {fields}

      <StateErrors state={state} />

      {submitButton}
    </ActionForm>
  );
}
