// authentication
export const SIGNED_TOKEN_FIELD_NAME = 'signed-token';
export const CSRF_FIELD_NAME = 'csrf-token';
export const PHONE_FIELD_NAME = 'phone-number';
export const EMAIL_FIELD_NAME = 'email';
export const PASSWORD_FIELD_NAME = 'password';
export const NEW_PASSWORD_FIELD_NAME = 'new-password';
export const CONFIRM_PASSWORD_FIELD_NAME = 'confirm-password';
export const REMEMBER_ME_FIELD_NAME = 'remember-me';
// newsletter
export const NEWSLETTER_EMAIL_FIELD_NAME = 'newsletter-email';
// cookies
export const COOKIE_FUNCTIONAL_FIELD_NAME = 'cookie-functional';
// feedback
export const FEEDBACK_TYPE_FIELD_NAME = 'feedback-type';
export const FEEDBACK_RATING_FIELD_NAME = 'feedback-rating';
export const FEEDBACK_COMMENT_FIELD_NAME = 'feedback-comment';
// referral
export const REFERRAL_EMAIL_FIELD_NAME = 'referral-email';
export const REFERRAL_ADDRESS_FIELD_NAME = 'referral-address';
// user
export const SETTINGS_EMAIL_NOTIFICATIONS_FIELD_NAME =
  'settings-email-notifications';
export const SETTINGS_PERIODIC_NEWSLETTER_FIELD_NAME =
  'settings-periodic-newsletter';
export const SETTINGS_PROMOTIONAL_NEWSLETTER_FIELD_NAME =
  'settings-promotional-newsletter';
export const SETTINGS_PRODUCT_NEWSLETTER_FIELD_NAME =
  'settings-product-newsletter';
// property
export const PROPERTY_ADDRESS_FIELD_NAME = 'property-address';
export const SERVICE_PACKAGE_FIELD_NAME = 'service-package';
// names
export const FIRST_NAME_FIELD = 'first-name';
export const LAST_NAME_FIELD = 'last-name';
// property
export const PROPERTY_NAME_FIELD = 'property-name';
// company
export const COMPANY_NAME_FIELD = 'company-name';
export const COMPANY_EMAIL_FIELD_NAME = 'company-email';
export const COMPANY_PHONE_NUMBER_FIELD_NAME = 'company-phone-number';
export const COMPANY_ADDRESS_FIELD_NAME = 'company-address';
// pod
export const POD_NAME_FIELD = 'pod-name';
// fleet
export const TRAVEL_MODE_FIELD_NAME = 'travel-mode';
export const TRAVEL_EXCLUSIONS_FIELD_NAME = 'travel-exclusions';
export const TRAVEL_COVERAGE_IN_MILES_FIELD_NAME = 'travel-coverage-in-miles';
export const TRAVEL_COVERAGE_IN_MINUTES_FIELD_NAME =
  'travel-coverage-in-minutes';
