import { Route } from 'next';

export const publicLinks: Record<string, Route> = {
  // marketing
  home: '/',
  agents: '/agents',
  managers: '/managers',
  // providers
  providers: '/providers',
  prospectProviders: '/providers/[address]' as Route<'address'>,
  // prices
  prices: '/prices',
  pricesProperty: '/prices/[property]' as Route<'property'>,
  pricesArea: '/prices/area/[zone]' as Route<'zone'>,
  // company
  privacy: '/privacy',
  terms: '/terms',
  cookies: '/cookies',
  // authentication
  signIn: '/signin',
  signup: '/signup',
  signUp: '/signup',
  resetPassword: '/reset-password',
  forgotPassword: '/forgot-password',
};

export const appLinks: Record<string, Route> = {
  app: '/app',
  account: '/app/account',
  notifications: '/app/notifications',
  wallet: '/app/account/wallet',
  onboarding: '/app/onboarding',
  onboardingCustomer: '/app/onboarding/customer',
  onboardingProvider: '/app/onboarding/provider',
  properties: '/app/properties',
  property: '/app/properties/[property]' as Route<'property'>,
  serviceHistory: '/app/properties/[property]/services' as Route<'property'>,
  fleet: '/app/fleet',
  pods: '/app/fleet/pods',
  routes: '/app/fleet/routes',
  pod: '/app/fleet/pods/[pod]',
  podRoutes: '/app/fleet/pods/[pod]/routes',
  // actions
  feedback: '/app/actions/feedback',
  referral: '/app/actions/referral',
  createProperty: '/app/new/property',
  createPod: '/app/new/pod',
};

export const internalLinks: Record<string, Route> = {
  internal: '/internal',
  insights: '/internal/insights',
  operations: '/internal/operations',
};

export const apiLinks: Record<string, string> = {
  graphql: '/api/graphql',
  quote: '/api/quote',
};

export function getQuotePropertyLink(addressId: string): Route {
  return `${publicLinks.pricesProperty}`.replace(
    '[property]',
    addressId,
  ) as Route;
}

export function getQuoteZipcodeLink(area: string): Route {
  return `${publicLinks.pricesArea}`.replace('[zone]', area) as Route;
}
